.drmZsmMeeting {
    padding: 0.625rem 1rem !important;
}

.drmZmsMeeting__inputMultiple .MuiOutlinedInput-root {
    /* border: 0.043rem solid #2cc26a !important; */
    border: 0.692008px solid #808080 !important;
    font-family: "Roboto" !important;
    font-weight: 400;
    background-color: #ffffff;
    font-size: 0.731rem !important;
    min-height: 1.7rem !important;
    max-height: auto !important;
    padding: 0px !important;
    font-size: 0.523rem;
    border-radius: 0.3rem;
    top: -1px;
    /* border: 1px solid #2cc26a !important; */
}

.drmZmsMeeting__inputMultiple .MuiAutocomplete-option {
    font-size: 0.75rem;
    padding-left: 18px !important;
    padding-top: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    min-height: 32px !important;
}

.drmZmsMeeting__inputMultiple .MuiOutlinedInput-root input {
    font-size: 0.75rem;
    /* position: absolute; */
    height: 0px !important;
    padding: 13.5px 14px;
    width: 80% !important;
    padding: 0.75rem 0rem 0.75rem 0.75rem !important;
}

.drmZmsMeeting__inputMultiple .MuiAutocomplete-input::placeholder {
    color: #808080 !important;
    font-weight: 400 !important;
    opacity: 1 !important;
    height: auto;
}

.drmZmsMeeting__inputMultiple .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    top: -8px;
    font-size: 0.75rem;
    color: #808080;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    color: #808080 !important;
    background-color: #ffffff;
    padding: 0 0.313rem !important;
    top: 0px !important;
}

.myDealer__categorySelected .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root .Mui-focused {
    color: #808080 !important;
}

.drmZmsMeeting__inputMultiple .MuiIconButton-root .css-i4bv87-MuiSvgIcon-root {
    background-image: url(../../Assets/Images/downArrow.png) !important;
    color: #808080 !important;
    font-family: "Roboto" !important;
    font-weight: 400;
    font-size: 0.731rem !important;
    background-size: 16px;
    width: 1.5em;
    height: 1.7em;
    position: inherit;
    margin-top: 4px;
}

.drmZmsMeeting__inputMultiple .MuiIconButton-root .css-vubbuv {
    background-image: url(../../Assets/Images/downArrow.png) !important;
    color: #808080 !important;
    font-family: "Roboto" !important;
    font-weight: 400;
    font-size: 0.731rem !important;
    background-size: 16px;
    width: 1.5em;
    height: 1.7em;
    position: inherit;
    margin-top: 4px;
}

.drmZmsMeeting__inputMultiple .MuiIconButton-root .css-i4bv87-MuiSvgIcon-root path {
    display: none !important;
}

.drmZmsMeeting__inputMultiple .MuiIconButton-root .css-vubbuv path {
    display: none !important;
}

.drmZmsMeeting__inputMultiple:focus-visible {
    outline: none !important;
    outline-style: none !important;
}

.drmZmsMeeting__inputMultiple .MuiButtonBase-root {
    margin-right: -10px;
}

.drmZmsMeeting__inputMultiple .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    position: fixed !important;
}

.drmZmsMeeting__inputMultiple .css-igs3ac {
    position: fixed !important;
}

.drmZmsMeeting__inputMultiple .MuiAutocomplete-endAdornment {
    margin-right: 9px;
}

.drmZmsMeeting__inputMultiple .MuiAutocomplete-clearIndicator {
    margin-right: 10px;
}

.drmZmsMeeting__textfield .MuiOutlinedInput-input {
    padding: 13.5px 14px;
    border: 0.692008px solid #808080 !important;
    font-family: "Roboto" !important;
    font-weight: 400;
    background-color: #ffffff;
    font-size: 0.731rem !important;
    min-height: 1.7rem !important;
    max-height: auto !important;
    /* padding: 0px !important; */
    padding: 0px 10px !important;
    font-size: 0.523rem;
    border-radius: 0.3rem;
    top: -1px;
}

.drmZmsMeeting__textfield .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus {
    color: #2cc26a !important;
}

.drmZmsMeeting__textfield .MuiAutocomplete-input::placeholder {
    color: #2cc26a !important;
    font-weight: 400 !important;
    opacity: 1 !important;
    height: auto;
}

.drmZmsMeeting__textfield .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    top: -8px;
    font-size: 0.75rem;
    color: #2cc26a;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    color: #2cc26a !important;
    background-color: #ffffff;
    padding: 0 0.313rem !important;
    top: 0px !important;
}

.drmZmsMeeting__textfield .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root .Mui-focused {
    color: #2cc26a !important;
}

.drmZmsMeeting__selectLabel {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 0.688rem !important;
    line-height: 0.813rem;
    color: #808080;
    margin-top: 5px;
    margin-bottom: 5px;
    align-self: center;
}

.drmZmsMeeting__selectRadio .css-ahj2mt-MuiTypography-root {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 0.688rem;
    line-height: 0.813rem;
}

.MarketVisitReport__selectLabel {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 0.688rem;
    line-height: 0.813rem;
    color: #808080;
}

.MarketVisitReport__dateField {
    width: 100%;
    white-space: nowrap;
    height: 1.7rem;
    margin-bottom: 0.8rem;
    border: 0.692008px solid #808080;
    border-radius: 0.3rem;
    color: #808080;
    padding: 0px 5px;
}

.drmZmsMeeting__inputLabel {
    display: inline-block;
    padding: 5px 5px 5px 0px;
    cursor: pointer;
    /* color: #fff; */
    border-radius: 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.188rem;
    color: #2cc26a;
    text-align: end;
}

.drmZmsMeeting__inputFile {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.drmZmsMeeting__inputWrapper {
    display: flex;
    justify-content: end;
}

/* .drmZmsMeeting__date .MuiInputBase-root {
    border: 0.692008px solid #808080 !important;
} */

.drmZmsMeeting__date :focus-visible {
    outline: none !important;
    outline-style: none !important;
}

.drmZmsMeeting__date :focus {
    outline: none !important;
    outline-style: none !important;
}

.drmZmsMeeting__date .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root .Mui-focused {
    color: #2cc26a !important;
}

.drmZmsMeeting__attachIcon {
    height: 1.5em;
    width: 1.5em;
    color: #2cc26a;
    margin-right: 5px;
    align-self: center;
    margin-left: 10px;
}


.drmZmsMeeting__submitButton {
    padding: 0 !important;
    width: 100%;
    background-color: #3901FF !important;
    color: white !important;
    font-size: 13px !important;
    height: 1.8rem;
    margin-right: 10px;
    text-transform: none;
}

.drmZmsMeeting__remarkBox {
    background-color: #ffcccc;
    padding: 0.625rem 1rem !important;
    border-radius: 5px;
    color: #900;
}

.drmZmsMeeting__remarkLable {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 0.78rem;
    line-height: 0.813rem;
    color: rgb(153, 0, 0);
    margin-top: 10px;
    margin-right: 10px;
}

.drmZmsMeeting__remark {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 0.688rem;
    line-height: 0.813rem;
    color: rgb(153, 0, 0);
    margin-top: 10px;
}

.newFeedback__submitBtn {
    background: #2cc26a !important;
    border-radius: 0.3rem !important;
    border-color: #2cc26a !important;
    color: #f9f9f9 !important;
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 0.606rem !important;
    line-height: 0.8rem;
    white-space: nowrap;
    width: 20%;
    padding: 4px !important;
}

.error_masg {
    font-size: 10px;
    color: #e60000;
    font-weight: 500;
    margin-bottom: 0px !important;
}